.bell {
  -webkit-animation: ring 4s 0.7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 4s 0.7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 4s 0.7s ease-in-out infinite;
  transform-origin: 50% 4px;
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }

  1% {
    -webkit-transform: rotateZ(30deg);
  }

  3% {
    -webkit-transform: rotateZ(-28deg);
  }

  5% {
    -webkit-transform: rotateZ(34deg);
  }

  7% {
    -webkit-transform: rotateZ(-32deg);
  }

  9% {
    -webkit-transform: rotateZ(30deg);
  }

  11% {
    -webkit-transform: rotateZ(-28deg);
  }

  13% {
    -webkit-transform: rotateZ(26deg);
  }

  15% {
    -webkit-transform: rotateZ(-24deg);
  }

  17% {
    -webkit-transform: rotateZ(22deg);
  }

  19% {
    -webkit-transform: rotateZ(-20deg);
  }

  21% {
    -webkit-transform: rotateZ(18deg);
  }

  23% {
    -webkit-transform: rotateZ(-16deg);
  }

  25% {
    -webkit-transform: rotateZ(14deg);
  }

  27% {
    -webkit-transform: rotateZ(-12deg);
  }

  29% {
    -webkit-transform: rotateZ(10deg);
  }

  31% {
    -webkit-transform: rotateZ(-8deg);
  }

  33% {
    -webkit-transform: rotateZ(6deg);
  }

  35% {
    -webkit-transform: rotateZ(-4deg);
  }

  37% {
    -webkit-transform: rotateZ(2deg);
  }

  39% {
    -webkit-transform: rotateZ(-1deg);
  }

  41% {
    -webkit-transform: rotateZ(1deg);
  }

  43% {
    -webkit-transform: rotateZ(0);
  }

  100% {
    -webkit-transform: rotateZ(0);
  }
}

@-moz-keyframes ring {
  0% {
    -moz-transform: rotate(0);
  }

  1% {
    -moz-transform: rotate(30deg);
  }

  3% {
    -moz-transform: rotate(-28deg);
  }

  5% {
    -moz-transform: rotate(34deg);
  }

  7% {
    -moz-transform: rotate(-32deg);
  }

  9% {
    -moz-transform: rotate(30deg);
  }

  11% {
    -moz-transform: rotate(-28deg);
  }

  13% {
    -moz-transform: rotate(26deg);
  }

  15% {
    -moz-transform: rotate(-24deg);
  }

  17% {
    -moz-transform: rotate(22deg);
  }

  19% {
    -moz-transform: rotate(-20deg);
  }

  21% {
    -moz-transform: rotate(18deg);
  }

  23% {
    -moz-transform: rotate(-16deg);
  }

  25% {
    -moz-transform: rotate(14deg);
  }

  27% {
    -moz-transform: rotate(-12deg);
  }

  29% {
    -moz-transform: rotate(10deg);
  }

  31% {
    -moz-transform: rotate(-8deg);
  }

  33% {
    -moz-transform: rotate(6deg);
  }

  35% {
    -moz-transform: rotate(-4deg);
  }

  37% {
    -moz-transform: rotate(2deg);
  }

  39% {
    -moz-transform: rotate(-1deg);
  }

  41% {
    -moz-transform: rotate(1deg);
  }

  43% {
    -moz-transform: rotate(0);
  }

  100% {
    -moz-transform: rotate(0);
  }
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }

  1% {
    transform: rotate(30deg);
  }

  3% {
    transform: rotate(-28deg);
  }

  5% {
    transform: rotate(34deg);
  }

  7% {
    transform: rotate(-32deg);
  }

  9% {
    transform: rotate(30deg);
  }

  11% {
    transform: rotate(-28deg);
  }

  13% {
    transform: rotate(26deg);
  }

  15% {
    transform: rotate(-24deg);
  }

  17% {
    transform: rotate(22deg);
  }

  19% {
    transform: rotate(-20deg);
  }

  21% {
    transform: rotate(18deg);
  }

  23% {
    transform: rotate(-16deg);
  }

  25% {
    transform: rotate(14deg);
  }

  27% {
    transform: rotate(-12deg);
  }

  29% {
    transform: rotate(10deg);
  }

  31% {
    transform: rotate(-8deg);
  }

  33% {
    transform: rotate(6deg);
  }

  35% {
    transform: rotate(-4deg);
  }

  37% {
    transform: rotate(2deg);
  }

  39% {
    transform: rotate(-1deg);
  }

  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0);
  }
}

.introjs-tooltip {
  width: 600px !important;
}

@media (max-width: 1366px) {
  .introjs-tooltip {
    top: 60px !important;
  }
  .introjs-arrow {
    display: none !important;
  }
}

/* .introjs-prevbutton {
  display: none !important;
} */

.introjs-tooltip-title {
  font-family: "Archia Semibold" !important;
}

.introjs-tooltipReferenceLayer * {
  font-family: "Silka" !important;
}

.introjs-nextbutton {
  background-color: #c0ff06;
  font-family: "Silka" !important;
  color: #252e3a;
  text-shadow: none;
  border: 1px solid black;
}

.introjs-prevbutton {
  background-color: #252e3a;
  font-family: "Silka" !important;
  color: white;
  text-shadow: none;
  border: 1px solid black;
}

.introjs-disabled {
  background-color: #d3d5d8;
  color: #7c8289;
  border: 1px solid #7c8289;
  display: none;
}

.introjs-disabled:hover {
  background-color: #d3d5d8 !important;
  color: #7c8289 !important;
  border: 1px solid #7c8289 !important;
}

.introjs-disabled:focus {
  background-color: #d3d5d8 !important;
  color: #7c8289 !important;
  border: 1px solid #7c8289 !important;
}

.introjs-skipbutton {
  display: none !important;
  /* background-image: url("/src/Dexta_assets/closeModal.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  display: inline-block;
  text-indent: -9999px; */
}

.introjs-skipbutton {
  position: absolute !important;
  top: 10px;
  right: 15px;
}

.introjs-skipbutton:hover {
  opacity: 0.8;
  cursor: pointer;
}

.introjs-nextbutton:focus {
  background-color: #c0ff06;
  color: #252e3a;
  box-shadow: none;
}

.introjs-prevbutton:focus {
  background-color: #c0ff06;
  border: 1px solid black;
  color: #252e3a;
  box-shadow: none;
}

.introjs-nextbutton:hover {
  background-color: #252e3a;
  color: white;
}

.introjs-prevbutton:hover {
  background-color: #c0ff06;
  color: #252e3a;
  border: 1px solid black;
}

.customYesButton {
  box-sizing: content-box;
  position: relative;
  overflow: visible;
  padding: 0.5rem 1rem;
  border: 1px solid #bdbdbd;
  text-decoration: none;
  text-shadow: 1px 1px 0 #fff;
  font-size: 14px;
  color: #424242;
  white-space: nowrap;
  cursor: pointer;
  outline: 0;
  background-color: #f4f4f4;
  border-radius: 0.2em;
  zoom: 1;
  display: inline;
}

.customNoButton {
  box-sizing: content-box;
  position: relative;
  overflow: visible;
  padding: 0.5rem 1rem;
  border: 1px solid red;
  text-decoration: none;
  font-size: 14px;
  color: white;
  white-space: nowrap;
  cursor: pointer;
  outline: 0;
  background-color: red;
  border-radius: 0.2em;
  zoom: 1;
  display: inline;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  color: red;
  font-size: 20px;
  cursor: pointer;
  z-index: 4000;
}

.close-button:hover {
  color: red;
}
