/* Set default font-family */
.app .ql-editor {
  font-family: "Archia Bold";
}

/* Set dropdown font-families */

.ql-picker.ql-font .ql-picker-label[data-value="Archia"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Archia"]::before {
  font-family: "Archia Bold", cursive;
  content: "Archia";
}

.ql-picker.ql-font .ql-picker-label[data-value="Silka"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Silka"]::before {
  font-family: "Silka", cursive;
  content: "Silka";
}

/* Add this new rule */
.ql-font-Silka {
  font-family: "Silka", cursive !important;
}

/* Snow Theme */
.app .ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

/* Bubble Theme */
.app .ql-bubble .ql-editor {
  border: 1px solid #ccc;
  border-radius: 0.5em;
}

.app .ql-editor {
  min-height: 18em;
}

.ql-snow .ql-picker-options .ql-picker-item {
  font-family: "Archia Bold";
  &::before {
    content: attr(data-value) !important;
  }
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: attr(data-value) !important;
}

.ql-snow {
  .ql-picker {
    &.ql-size {
      .ql-picker-label,
      .ql-picker-item {
        &::before {
          content: attr(data-value) !important;
        }
      }
    }
  }
}

.ql-snow .ql-color-picker .ql-picker-options .ql-picker-item::before {
  content: "" !important; /* Remove hex code */
}
