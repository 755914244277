.containerAssessment {
    display: flex;
    overflow: scroll;
    width: 100%;
    max-width: 100%;
    padding-left: 14px;
    border-radius: 5px;
}

.containerAssessment input {
    width: 100%;
    min-width: 50%;
    border: none;
    border-radius: 5px;
    padding: 14px;
    padding-left: 14px;
}

.tag {
    display: flex;
    align-items: center;
    margin: 7px 0;
    margin-right: 10px;
    padding: 0 10px;
    padding-right: 5px;
    border-radius: 5px;
    background-color: #4a9bb979;
    white-space: nowrap;
    color: #767676;
}

.tag button {
    display: flex;
    padding: 6px;
    border: none;
    background-color: unset;
    cursor: pointer;
    color: #767676;
}

.crossBtn {
    background: url("../../../../Assets/crossblue.png");
    background-repeat: no-repeat;
    background-size: 10px;
}

.crossBtn:hover {
    background: url("../../../../Assets/crossred.png");
    background-repeat: no-repeat;
    background-size: 10px;
}
 /*  */
  .yuzde {
 padding: 1px;
    width: 100%;
    border: 2px solid #4A9CB9;
    height: 20px;
    border-radius: 20px;
}

.icerik {
    background-color: #4A9CB9;
    height: 15px;
    border-radius: 20px;
}

.per {
    font-weight: 640;
    font-size: 15px;
    float: right;
    margin-right: 7px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
   -webkit-animation: bigger 1.5s
}
.btn_yes{
  
    width: 36px;
    border-radius: 5px;
}
.btn_no{
    background:#B20000;
    width: 36px;
    border-radius: 5px;
}

/*  */
/* DropdownMenu.module.css */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdownToggle {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px 10px;
  cursor: pointer;
}

.arrow {
  margin-right: 5px;
}

.dropdownContent {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  display: none;
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdownContent li {
  padding: 5px 10px;
  cursor: pointer;
}

.dropdownContent li:hover {
  background-color: #f0f0f0;
}

.dropdown:hover .dropdownContent {
  display: block;
}

/*  */

.w3review{
border: 1px solid;
border-color:  #B6B6B6;
border-radius: 5px;
padding-left: 10px;
}




/*3 Column Layout*/
		@media only screen and (min-width: 1024px) {
			.wrap {
				width: 1024px;
				margin: 0 auto;
			}
			
			#main-content {
				width: 50%;
				float: left;
			}
			
			#sidebar-left {
				width: 25%;
				float: left;
			}
			
			#sidebar-right {
				width: 25%;
				float: left;
			}
		}
		
		/*Wide Layout*/
		@media only screen and (min-width: 1200px) {
			.wrap {
				width: 1140px;
				margin: 0 auto;
			}
		}
		
		/*Move Sidebar Position*/
		@media only screen and (min-width: 1024px) {
			.wrap, #main-content, #sidebar-left, #sidebar-right {
				position: relative;
			}
			
			#main-content, #sidebar-left, #sidebar-right {
				top: 0;
			}
			
			#sidebar-right {
				right: 0;
			}
			
			#sidebar-left {
				left: -50%; /*Width of #main-content*/
			}
			
			#main-content {
				left: 25%; /*Width of #left-sidebar*/
			}
		}
		
		/*Optional 2 Column Layout*/
		@media only screen and (min-width: 768px) and (max-width: 1024px) {
			#sidebar-right {
				width: 50%;
			}
			#sidebar-left {
				width: 50%;
			}
			#main-content {
				position: relative;
				top: 0;
				left: 0;
			}
		}