@tailwind base;
@tailwind components;
@tailwind utilities;

/* Global styles for hiding scrollbar */
@layer utilities {
  @variants responsive {
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    .no-scrollbar {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }
}

/* Override styles for a specific component to enable scrolling */
.enable-scrollbar {
  -ms-overflow-style: auto; /* IE and Edge */
  scrollbar-width: auto; /* Firefox */
}

.enable-scrollbar ::-webkit-scrollbar {
  -ms-overflow-style: auto; /* IE and Edge */
  scrollbar-width: auto; /* Firefox */
  width: 0px;
  height: 10px;
}

.enable-scrollbar2 {
  -ms-overflow-style: auto; /* IE and Edge */
  scrollbar-width: auto; /* Firefox */
}

.enable-scrollbar2 ::-webkit-scrollbar {
  -ms-overflow-style: auto; /* IE and Edge */
  scrollbar-width: auto; /* Firefox */
  width: 5px;
}

.enable-scrollbar2 ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #70757a;
  border-radius: 10px;
}

.enable-scrollbar2 ::-webkit-scrollbar-thumb {
  background: #70757a;
  border-radius: 10px;
}

.enable-scrollbar2 ::-webkit-scrollbar-thumb:hover {
  background: #70757a;
}

/* Your custom scrollbar styles */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #4a9cb9;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #4a9cb9;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #4a9cb9;
}

* {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

@font-face {
  font-family: "Silka Light";
  src: local("Silka Light"),
    url("./fonts/Silka Fonts/Silka-Light.otf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Silka";
  src: local("Silka"),
    url("./fonts/Silka Fonts/Silka-Regular.otf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Silka Bold";
  src: local("Silka Bold"),
    url("./fonts/Silka Fonts/Silka-Bold.otf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Archia Bold";
  src: local("Archia Bold"),
    url("./fonts/Archia/Archia-Bold.otf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Archia Semibold";
  src: local("Archia Semibold"),
    url("./fonts/Archia/Archia-SemiBold.otf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url("./fonts/Inter/static/Inter-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Inter Bold";
  src: local("Inter Bold"),
    url("./fonts/Inter/static/Inter-Bold.ttf") format("truetype");
  font-weight: bold;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f7f7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.tooltip_element {
  position: relative;
}

.tooltip_new {
  position: absolute;
  background-color: black;
  color: white;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  opacity: 0;
  top: -10px;
  left: 30px;
  padding-right: 10px;
  padding-left: 10px;
  width: max-content;
}

.tooltip_element:hover .tooltip_new {
  opacity: 1;
}

.Toastify__toast--success {
  padding-left: 40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Hide the default success icon */
.Toastify__toast--success .Toastify__toast-body > div:first-child {
  display: none;
}

/* Add custom success icon */
.Toastify__toast--success::before {
  content: "";
  background: url("../src/Dexta_assets/tickgreen2.png") no-repeat center center;
  background-size: contain;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
}

/* Ensure text content is positioned correctly */
.Toastify__toast--success .Toastify__toast-body {
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: 40px;
}

/* Style the close button with a custom icon */
.Toastify__toast--success .Toastify__close-button {
  background: url("../src/Dexta_assets/closeModal.png") no-repeat center center;
  background-size: contain;
  opacity: 1 !important;
  width: 24px;
  height: 24px;
  flex: none;
}

.Toastify__toast--success .Toastify__close-button > svg {
  display: none;
}

/* Customize the progress bar color */
.Toastify__toast--success .Toastify__progress-bar {
  background-color: #c0ff06 !important;
}

.Toastify__toast--error .Toastify__progress-bar {
  background-color: #ff5812 !important;
}

/* Toast error */
.Toastify__toast--error {
  padding-left: 40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Hide the default success icon */
.Toastify__toast--error .Toastify__toast-body > div:first-child {
  display: none;
}

/* Add custom success icon */
.Toastify__toast--error::before {
  content: "";
  background: url("../src/Dexta_assets/err3.png") no-repeat center center;
  background-size: contain;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
}

/* Ensure text content is positioned correctly */
.Toastify__toast--error .Toastify__toast-body {
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: 40px;
}

/* Style the close button with a custom icon */
.Toastify__toast--error .Toastify__close-button {
  background: url("../src/Dexta_assets/closeModal.png") no-repeat center center;
  background-size: contain;
  opacity: 1 !important;
  width: 24px;
  height: 24px;
  flex: none;
}
.Toastify__toast--error .Toastify__close-button > svg {
  display: none;
}
