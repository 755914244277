.w-color-saturation {
  height: 250px !important;
}

.backgroundSettings {
  background-image: url("../../../Assets/transparent3.png");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
}

.container2 {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1 !important;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black overlay */
  z-index: 999;
  /* Make sure the overlay is above other content */
}

.cropper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50vw;
  height: 70vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
  background-color: white;
  z-index: 1000;
  /* box-shadow: 0px 10px 9px 0px rgba(0, 0, 0, 0.09); */
}

.cropper2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40vw;
  height: 60vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
  background-color: white;
  z-index: 1000;
  /* box-shadow: 0px 10px 9px 0px rgba(0, 0, 0, 0.09); */
}

@media (max-width: 640px) {
  .cropper2 {
    width: 90vw;
  }
}

@media (max-width: 640px) {
  .cropper {
    width: 90vw;
    height: 80vh;
  }
}

@media (max-width: 1400px) {
  .cropper {
    width: 100vh;
    height: 95vh;
  }
}

input[type="radio"] {
  accent-color: #252e3a;
}

.btn {
  font-size: 1rem;
  font-weight: 500;
  color: #ffffff;
  background: #1895b0;
  padding: 0.5rem 1.5rem;
  border-radius: 0.2rem;
  border: 2px solid #1895b0;
  cursor: pointer;
  margin: 1rem;
}

.btn-outline {
  background: #ffffff;
  color: #1895b0;
  border: 2px solid #1895b0;
}

.action-btns {
  padding-bottom: 1rem;
}

.btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cropped-img {
  width: 300px;
  height: 300px;
  object-fit: contain;
}

.aspect-ratios {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 80px;
  font-size: 1rem;
  font-weight: 500;
}

.reactEasyCrop_Contain {
  background: "red" !important;
}

.settingFade-enter {
  opacity: 0.01;
}

.settingFade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.settingFade-exit {
  opacity: 1;
}

.settingFade-exit-active {
  opacity: 0.01;
  transition: opacity 0ms ease-in;
}

.three-body {
  --uib-size: 35px;
  --uib-speed: 0.8s;
  --uib-color: #c0ff06;
  position: relative;
  display: inline-block;
  height: var(--uib-size);
  width: var(--uib-size);
  animation: spin78236 calc(var(--uib-speed) * 2.5) infinite linear;
}

.three-body__dot {
  position: absolute;
  height: 100%;
  width: 30%;
}

.three-body__dot:after {
  content: "";
  position: absolute;
  height: 0%;
  width: 100%;
  padding-bottom: 100%;
  background-color: var(--uib-color);
  border-radius: 50%;
}

.three-body__dot:nth-child(1) {
  bottom: 5%;
  left: 0;
  transform: rotate(60deg);
  transform-origin: 50% 85%;
}

.three-body__dot:nth-child(1)::after {
  bottom: 0;
  left: 0;
  animation: wobble1 var(--uib-speed) infinite ease-in-out;
  animation-delay: calc(var(--uib-speed) * -0.3);
}

.three-body__dot:nth-child(2) {
  bottom: 5%;
  right: 0;
  transform: rotate(-60deg);
  transform-origin: 50% 85%;
}

.three-body__dot:nth-child(2)::after {
  bottom: 0;
  left: 0;
  animation: wobble1 var(--uib-speed) infinite calc(var(--uib-speed) * -0.15)
    ease-in-out;
}

.three-body__dot:nth-child(3) {
  bottom: -5%;
  left: 0;
  transform: translateX(116.666%);
}

.three-body__dot:nth-child(3)::after {
  top: 0;
  left: 0;
  animation: wobble2 var(--uib-speed) infinite ease-in-out;
}

@keyframes spin78236 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes wobble1 {
  0%,
  100% {
    transform: translateY(0%) scale(1);
    opacity: 1;
  }

  50% {
    transform: translateY(-66%) scale(0.65);
    opacity: 0.8;
  }
}

@keyframes wobble2 {
  0%,
  100% {
    transform: translateY(0%) scale(1);
    opacity: 1;
  }

  50% {
    transform: translateY(66%) scale(0.65);
    opacity: 0.8;
  }
}

.PhoneInputCountrySelect {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: white;
  border: 1px solid #ccc;
  padding: 8px;
  cursor: pointer;
}

/* Style for the dropdown options */
.PhoneInputCountrySelect option {
  background-color: white;
  color: black;
}

/* Hover effect for the dropdown options */
.PhoneInputCountrySelect option:hover {
  background-color: red;
  border-radius: 500;
}

/* Add hover effect to dropdown options */
.custom-phone-input .PhoneInputCountrySelect option:hover {
  background-color: black;
  color: white; /* Change text color to white on hover */
}

option {
  font-family: "Silka";
}

option:checked {
  background: #252e3a;
  color: white;
}
