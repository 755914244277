.loader2 {
    display: flex;
    transition: all 0.4s;
  }
  
  .loader2 div {
    margin-left: 0.8rem;
    background-color: rgb(34, 34, 34);
    box-shadow: inset 2px 2px 10px black;
    border-radius: 100%;
    height: 1rem;
    width: 1rem;
  }
  
  .box-load1 {
    animation: brighten 1.2s infinite;
  }
  
  .box-load2 {
    animation: brighten 1.2s infinite;
    animation-delay: .2s;
  }
  
  .box-load3 {
    animation: brighten 1.2s infinite;
    animation-delay: .4s;
  }
  
  @keyframes brighten {
    100% {
      background-color: rgb(165, 165, 165);
      box-shadow: none;
    }
  }
  