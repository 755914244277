/* Paragraph styles */
.html-content p {
  color: black !important;
  font-size: 13px;
  font-weight: 450;
}

.html-content h1 {
  color: black !important;
  font-size: 26px !important;
  font-family: Arial, Helvetica, sans-serif;
}

.html-content li {
  color: black !important;
  font-size: 13px;
}

.html-content ul {
  list-style-type: disc;
  margin-left: 40px;
  color: black !important;
  font-size: 13px;
}

.html-content ol {
  list-style-type: decimal;
  margin-left: 40px;
  color: black !important;
  font-size: 13px;
}