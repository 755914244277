.customStyle {
  font-family: Silka !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-size: 12px !important;
}

.custom-list {
  list-style-type: none;
  padding-left: 0;
}

.custom-list li {
  position: relative;
  padding-left: 1.5em;
}

.custom-list li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0.6em;
  width: 0.4em;
  height: 0.4em;
  border: 2px solid currentColor;
  border-radius: 50%;
}

.list-decimal {
  list-style-type: decimal;
}

.list-decimal > li {
  margin-bottom: 1em;
}
