.loadingplan {
  /* opacity: 5; */
  transition: opacity 0.8s ease-in-out;
  /* Increase the duration for a slower transition */
}

.loading-card {
  opacity: 0;
  transition: opacity 0.8s ease-in-out;
  /* Increase the duration for a slower transition */
}

.fade-in-text-validations {
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
}

.ql-clipboard {
  position: fixed !important;
}

.ql-container {
  height: 280px !important;
  overflow-y: auto;
}

.ql-editor {
  height: auto !important;
}

.icon-standard {
  width: 1.25rem; /* 20px */
  height: 1.25rem; /* 20px */
  color: #4a5568; /* Gray color similar to text-gray-600 */
  transition: color 0.2s;
}
.icon-standard:hover {
  color: #319795; /* Teal color similar to hover:text-teal-600 */
}

.textHover {
  color: #18272f;
  position: relative;
  text-decoration: none;
}

.textHover::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  border-radius: 2px;
  background-color: #18272f;
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.textHover:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}
