.Hero {
  background-image: url("../../Assets/Hero.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 130vh;
}

Hide scrollbar for Chrome,
Safari and Opera .scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(105, 196, 219);
  border-radius: 10px;
}

/* Handle */
.scrollbar::-webkit-scrollbar-thumb {
  background: rgb(105, 196, 219);
  border-radius: 10px;
}

/* Handle on hover */
.scrollbar::-webkit-scrollbar-thumb:hover {
  background: rgb(105, 196, 219);
}
