.fade-enter {
    opacity: 0.10;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}


.fD-enter {
    opacity: 0.10;
}

.fD-enter-active {
    opacity: 1;
    transition: opacity 150ms ease-in;
}

.fD-exit {
    opacity: 1;
}

.fD-exit-active {
    opacity: 0.01;
    transition: opacity 80ms ease-in;
}