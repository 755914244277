#overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity (0.5 for 50% opacity) */
    z-index: 999; /* Ensure it's above your modal */
  }
  .overlay-visible {
    display: block;
  }
  