.zoom-range::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #252e3a;
  background: #252e3a;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.zoom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #c0ff06;
  background: #c0ff06;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.controls:hover input[type="range"]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px 8px rgba(157, 177, 70, 0.16);
  border-radius: 50%;
}

.controls:hover input[type="range"]::-moz-range-thumb {
  box-shadow: 0px 0px 0px 8px rgba(157, 177, 70, 0.16);
}

.controls {
  position: absolute;
  bottom: 90px;
  left: 50%;
  transform: translateX(-50%);
  height: 40px;
  display: flex;
  align-items: center;
}

@media (max-width: 640px) {
  .controls {
    position: absolute;
    bottom: 90px;
    left: 50%;
    transform: translateX(-50%);
    height: 30px;
    display: flex;
    align-items: center;
  }
}

.slider {
  padding: 22px 0px;
}

.zoom-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 2px;
  background: #252e3a;
}
