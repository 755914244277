.animation {
  animation-name: spin;
  animation-duration: 1ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.card {
  width: 25rem;
  border-radius: 1rem;
  background: white;
  box-shadow: 4px 4px 15px rgba(#000, 0.15);
  position: relative;
  color: #434343;
}

.card .card__container {
  padding: 2rem;
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 1rem;
  position: relative;
}

.card .card__header {
  margin-bottom: 1rem;
  font-family: "Playfair Display", serif;
}

.card .card__body {
  font-family: "Roboto", sans-serif;
}

.card::before {
  position: absolute;
  top: 2rem;
  right: -0.5rem;
  content: "";
  background: #283593;
  height: 28px;
  width: 28px;
  transform: rotate(45deg);
}

.card::after {
  position: absolute;
  content: attr(data-label);
  top: 11px;
  right: -14px;
  padding: 0.5rem;
  width: 10rem;
  background: #3949ab;
  color: white;
  text-align: center;
  font-family: "Roboto", sans-serif;
  box-shadow: 4px 4px 15px rgba(26, 35, 126, 0.2);
}

.fade-in-text {
  animation: fadeIn 4s;
  -webkit-animation: fadeIn 4s;
  -moz-animation: fadeIn 4s;
  -o-animation: fadeIn 4s;
  -ms-animation: fadeIn 4s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
