/* Paragraph styles */
.html-content p {
  color: #888789 !important;
  font-size: 14px;
}

.html-content ul {
  margin-left: 15px;
  font-size: 14px;
  list-style: none;
  padding-left: 0; /* Ensure no default padding */
}

.html-content ul li {
  padding-left: 40px;
  margin-bottom: 6px;
  line-height: 1.6;
  position: relative;
}

.html-content ul li::before {
  content: "";
  position: absolute;
  top: 4px;
  left: 0;
  width: 16px;
  height: 16px;
  background: url("../../Dexta_assets/tickRed.png") no-repeat center center;
  background-size: contain;
}

.html-content span {
  list-style-type: disc;
  color: #888789 !important;
  font-size: 14px;
}

.html-content ol {
  list-style-type: decimal;
  color: #888789 !important;
  font-size: 14px;
  margin-left: 18px;
}

.html-content div {
  color: #888789 !important;
  font-size: 14px;
}

.html-content u {
  color: #888789 !important;
  font-size: 14px;
}
