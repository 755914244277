.relative {
  position: relative;
}

.tooltip {
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  background-color: #252e3a;
  color: white;
  padding: 5px;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.7);
}

.tooltip::after {
  content: "";
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent #333 transparent;
}

.relative:hover .tooltip {
  opacity: 1;
}

.input-range__track--active,
.input-range__slider {
  background: #4a9cb9 !important;
  border-color: #4a9cb9 !important;
}

.good {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.good2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.good3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.good4 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.iosClamping {
  font-family: "Silka";
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.introjs-tooltip {
  min-width: 500;
  max-width: 1000px;
}

.ribbon-container {
  position: relative;
}

.ribbon {
  position: absolute;
  top: 0;
  right: 0;
  transform: rotate(45deg);
  background-color: #4caf50;
  /* Use your ribbon color */
  color: #fff;
  font-weight: bold;
  padding: 8px;
  width: 120px;
  /* Adjust the width as needed */
  overflow: hidden;
}

/* styles.css or styles.scss */
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-20px);
  }

  60% {
    transform: translateY(-10px);
  }
}

.arrow-icon {
  display: flex;
  align-items: center;
  animation: bounce 2s infinite;
}

.arrow {
  color: #3490dc;
  /* Change the arrow color as needed */
  font-size: 24px;
  /* Adjust the icon size as needed */
  margin-left: 5px;
  /* Adjust the margin as needed */
}

.forcefulpadding {
  padding-bottom: 5rem !important;
}

.sticky-table tr > th:first-child,
.sticky-table tr > td:first-child {
  position: sticky;
  left: 0;
  z-index: 1;
}

.sticky-table tr > th:first-child::after,
.sticky-table tr > td:first-child::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 1px;
  background-color: #ccc;
}

.sticky-table tr > th:last-child,
.sticky-table tr > td:last-child {
  position: sticky;
  right: 0;
  z-index: 1;
}

.sticky-table tr > th:last-child::after,
.sticky-table tr > td:last-child::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 1px;
  background-color: #ccc;
}

.overflowscroll {
  overflow-x: hidden !important;
  width: 100% !important;
  height: 100% !important;
}

.containerz {
  height: 10rem !important;
}

input.thumb-red::-webkit-slider-thumb {
  background-color: red;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    255,
    255,
    255,
    0.8
  ); /* Semi-transparent white background */
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-overlay .loader {
  /* Your loader styles */
}

.tooltipxD {
  position: absolute;
  top: 50%;
  right: calc(100% + 10px);
  transform: translateY(-50%);
  background-color: #252e3a;
  color: white;
  padding: 5px;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.7);
}

.tooltipxD::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translateY(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent transparent #333;
}

.relative:hover .tooltipxD {
  opacity: 1;
}

.rotate-container {
  transform: rotate(90deg);
  transform-origin: center center;
  width: 100vh;
  height: 100vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
}

.table-container {
  overflow-x: auto;
  margin: 0 auto;
}

.responsive-table {
  width: 100%;
  border-collapse: collapse;
}

@media (max-width: 768px) {
  .responsive-table th,
  .responsive-table td {
    padding: 8px;
    font-size: 14px;
  }

  .responsive-table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
}
