/* Paragraph styles */
.html-content p {
  color: #252E3A !important;
  font-size: 14px;
}

.html-content ul {
  margin-left: 25px;
  color: #888789 !important;
  font-size: 14px;
  list-style: disc;
}


.html-content span {
  list-style-type: disc;
  color: #252E3A !important;
  font-size: 14px;
  background-color: transparent !important;
}

.html-content ol {
  list-style-type: decimal;
  color: #888789 !important;
  font-size: 14px;
  margin-left: 25px;
}

.html-content div {
  color: #252E3A !important;
  font-size: 14px;
}

.html-content u {
  color: #888789 !important;
  font-size: 14px;
}