.bg-image-sign-in {
    background-image: url('./Sign-in.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #252525;
    /* height: 100%; */
}

.sign-in-model {
    position: absolute;
    /* width: 500px; */
    /* height: 703px; */
    /* left: 751px; */
    /* top: calc(50% - 703px/2 - 0.5px); */

    /* background: #1D1D1D; */
    /* border-radius: 20px; */
}