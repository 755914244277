/* Paragraph styles */
.html-content p {
  color: #252e3a !important;
  font-size: 18px;
}

.html-content li {
  color: #252e3a !important;
  font-size: 18px;
}

.html-content ul {
  list-style-type: disc;
  margin-left: 20px;
  color: #252e3a !important;
  font-size: 18px;
}

.html-content span {
  list-style-type: disc;
  color: #252e3a !important;
  font-size: 18px;
}

.html-content ol {
  list-style-type: decimal;
  color: #252e3a !important;
  font-size: 18px;
  margin-left: 20px;
}

.html-content div {
  color: #252e3a !important;
  font-size: 18px;
}

.html-content u {
  color: #252e3a !important;
  font-size: 18px;
}

.html-content h1 {
  color: #252e3a !important;
  font-size: 25px;
}

.html-content h2 {
  color: #252e3a !important;
  font-size: 21px;
}

.html-content table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 18px;
  color: #252e3a;
  overflow-x: auto !important;
}

.html-content th,
.html-content td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  color: #252e3a;
}

.html-content th {
  background-color: #f2f2f2;
  color: #252e3a;
  font-weight: bold;
}

.html-content tr:nth-child(even) {
  background-color: #f9f9f9;
}

.html-content tr:hover {
  background-color: #f1f1f1;
}
