.containerz {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30vh; /* Optionally, set the height of the container to the viewport height for vertical centering */
  }
  
  .svgs {
    width: 3.25em;
    transform-origin: center;
    animation: rotate4 2s linear infinite;
  }
  
  .circles {
    fill: none;
    stroke-width: 2;
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    animation: dash4 1.5s ease-in-out infinite;
  }
  
  @keyframes rotate4 {
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes dash4 {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
  
    50% {
      stroke-dasharray: 90, 200;
      stroke-dashoffset: -35px;
    }
  
    100% {
      stroke-dashoffset: -125px;
    }
  }
  
  
.settingFade-enter {
    opacity: 0.01;
  }
  
  .settingFade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  
  .settingFade-exit {
    opacity: 1;
  }
  
  .settingFade-exit-active {
    opacity: 0.01;
    transition: opacity 0ms ease-in;
  }