.rating svg {
    cursor: pointer;
    stroke: #FFC700;
    strokeWidth: 30;
}

.rating div:hover svg {
    color: #FFC700;
}

.rating svg:hover~svg {
    color: silver;
}

.clickedstar {
    color: #FFC700;
}

.notclicked {
    color: silver;
}