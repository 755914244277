.relative {
    position: relative;
}

.tooltip2 {
    position: absolute;
    bottom: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
    background-color: #252E3A;
    color: white;
    padding: 5px;
    border-radius: 4px;
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
}

.tooltip2::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: transparent transparent #252E3A transparent;
}

.relative:hover .tooltip2 {
    opacity: 1;
}
